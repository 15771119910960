import React, { FunctionComponent, useEffect, useState } from 'react';
import CheckBox from '../components/CheckBox';
import Modal from '../components/Modal';
import SpacerTable from '../components/SpacerTable';
import GAHelper from '../helpers/GAHelper';
import ThemeHelper from '../helpers/ThemeHelper';
import { IEvent } from '../interfaces/IEvent';
import UserEventOverview from './UserEventOverview';

export interface IProps {
  event: IEvent;
  onClose: () => void;
}

const UserEventOverviewModal = ((props) => {
  const { event } = props;

  useEffect(() => {
    GAHelper.modal(`User Event Overview`);
  }, []);

  return (
    <Modal theme={ThemeHelper.getEventTheme(event)} onCloseClick={props.onClose}>
      <div className="content">
        <div className="ticket-rip" />

        <div className="body">
          <SpacerTable>
            <h1>{event.Name}</h1>
            <div>{event.Organisation.Name}</div>

            {event.Venue && event.Venue.Name && event.Venue.Name.length > 0 && (
              <div>
                {event.Venue.Name}
                {event.Venue.Postcode && event.Venue.Postcode.length > 0 ? ', ' + event.Venue.Postcode : ''}
              </div>
            )}
          </SpacerTable>

          <div className="spacer" />

          <UserEventOverview event={event} />
        </div>

        <div className="ticket-rip bottom" />
      </div>
    </Modal>
  );
}) as FunctionComponent<IProps>;

export interface IUserOverviewProps {
  event: IEvent;
}

export default UserEventOverviewModal;
