import Cookies from 'js-cookie';
import linq from 'linq';
import moment from 'moment';
import AdminApi from '../api/AdminApi';
import EnvironmentVariables from '../EnvironmentVariables';
import { IEvent } from '../interfaces/IEvent';
import { IEventDate } from '../interfaces/IEventDate';
import { IOrganisation } from '../interfaces/IOrganisation';
import { AdmissionTypes } from '../views/Editor/event/TicketSetupSection';
import { IImageUploadRequest, ImageRequestGroup } from '../views/Editor/ImageUploader';
import DateHelper from './DateHelper';
import OrganisationHelper from './OrganisationHelper';
import SeatingPlanHelper from './SeatingPlanHelper';
import ThemeHelper from './ThemeHelper';
import UserHelper from './UserHelper';
import VenueHelper from './VenueHelper';

export interface IAvailableDatesModel {
  visibleDates: linq.IOrderedEnumerable<IEventDate>;
  datesFromToString: string;
}

export default class EventHelper {
  public static getRichTextCharLength(v) {
    return v.replace(/<(.|\n)*?>/g, '').length;
  }

  public static manageEvents(navigate) {
    const lastUsedOrganisation = Cookies.get('last-managed-organisation');

    if (lastUsedOrganisation) {
      Cookies.set('last-managed-organisation', lastUsedOrganisation, { expires: 365, path: '/' });
      navigate(`/Organisation/${lastUsedOrganisation}/Events`);
    } else if (UserHelper.currentUser.OrganisationTags?.length > 0) {
      const first = UserHelper.currentUser.OrganisationTags[0];
      Cookies.set('last-managed-organisation', first, { expires: 365, path: '/' });
      navigate(`/Organisation/${first}/Events`);
    } else {
      AdminApi.request('GET', `/api/UserOrganisations`)
        .then((response: string) => {
          if (response == null) {
            alert('No response from user organisations.');
          } else {
            const organisations = JSON.parse(response) as IOrganisation[];
            if (organisations.length == 0) {
              alert('Cannot find any organisations for this user.');
            } else {
              const first = organisations[0].OrganisationTag;
              Cookies.set('last-managed-organisation', first, { expires: 365, path: '/' });
              navigate(`/Organisation/${first}/Events`);
            }
          }
        })
        .catch((message) => {
          alert('Cannot find any organisations for this user.');
        });
    }
  }

  public static copyEvent(o: IOrganisation, e: IEvent) {
    const newEvent: IEvent = {
      ...e,
      Id: 0,
      Dates: [],
      EventTag: '',
      Organisation: o,
      OrganisationId: o.Id,
      EventDateIds: [],
      Theme: { ...e.Theme, Id: null, BackgroundImageUrl: null, HeaderImageUrl: null },
      Discounts: [],
      Questions: [],
      ImageUrl: '',
      Images: [],
      SeatingPlans: [],
      Categories: [],
      AllocatedCategoryGroups: [],
      UnallocatedCategoryGroups: [],
      PersonnelGroups: [],
    };

    if (newEvent.UseSeatingPlan && e.SeatingPlans && e.SeatingPlans.length > 0) {
      e.SeatingPlans.forEach((sp) => {
        newEvent.SeatingPlans.push({ ...SeatingPlanHelper.clearSeatCategories(sp), Id: 0 });
      });
    }

    return newEvent;
  }

  public static save(
    event: IEvent,
    setBusyMessage: (message: string) => void,
    imageRequests: { [key: string]: IImageUploadRequest } = null,
  ): Promise<IEvent> {
    setBusyMessage && setBusyMessage('Saving event...');

    if (event.UseSeatingPlan && event.SeatingPlans && event.SeatingPlans.length > 0) {
      const failedSeats = [];
      event.SeatingPlans.forEach((seatingPlan) => {
        seatingPlan.Seats.forEach((seat) => {
          const seatCategories = seatingPlan.SeatCategories.filter(
            (seatCategory) =>
              (seatCategory.Id === 0 && seatCategory.Guid === seat.SeatCategoryGuid) ||
              (seat.SeatCategoryId !== 0 && seatCategory.Id === seat.SeatCategoryId),
          );

          if (seatCategories.length === 0) {
            if (seatingPlan.SeatCategories && seatingPlan.SeatCategories.length > 0) {
              seat.SeatCategoryId = seatingPlan.SeatCategories[0].Id;
              seat.SeatCategoryGuid = seatingPlan.SeatCategories[0].Guid;
              seat.SeatCategoryName = seatingPlan.SeatCategories[0].Name;
              seat.SeatCategoryColour = seatingPlan.SeatCategories[0].Colour;
              seat.SeatCategory = seatingPlan.SeatCategories[0];
            } else {
              failedSeats.push(seat);
            }
          }
        });
      });
      if (failedSeats.length > 0) {
        return Promise.reject(`Seats have got no associated seat category. Please fix this before continuing to save.`);
      }
    }

    return AdminApi.request('PUT', '/api/EventEdit', event)
      .then((e: IEvent) => {
        if (e.ValidationError && e.ValidationError.length > 0) {
          var error = e.ValidationError;
          e.ValidationError = null;

          event.Dates.forEach((ed) => (ed.OrphanedSeats = []));

          e.Dates.forEach((ed) => {
            const eventDate = linq.from(event.Dates).firstOrDefault((edd) => edd.Id === ed.Id);

            if (eventDate && ed.OrphanedSeats && ed.OrphanedSeats.length > 0) {
              eventDate.OrphanedSeats = ed.OrphanedSeats;
            }
          });

          return Promise.reject(error);
        }

        event.Id = e.Id;

        if (imageRequests) {
          setBusyMessage && setBusyMessage('Saving event images...');
          return EventHelper.saveImages(e, setBusyMessage, imageRequests);
        } else {
          return e;
        }
      })
      .then((e) => {
        setBusyMessage && setBusyMessage('Updating event cache...');
        UserHelper.refreshUserToken();
        return AdminApi.request('PUT', '/api/EventEdited', e);
      })
      .then((e) => {
        setBusyMessage && setBusyMessage('All done!');
        return e;
      });
  }

  public static saveImages(
    event: IEvent,
    setBusyMessage: (message: string) => void,
    imageRequests: { [key: string]: IImageUploadRequest } = null,
  ): Promise<IEvent> {
    setBusyMessage && setBusyMessage('Saving event images...');

    return Promise.all(
      Object.keys(imageRequests).map((key) => {
        const imageRequest = imageRequests[key];

        const formData = new FormData();
        formData.append('eventId', event.Id.toString());
        formData.append('group', imageRequest.group);
        formData.append('file', imageRequest.file);

        return fetch(
          `${EnvironmentVariables.ADMIN_API_URL}/api/ImageUpload?eventId=${event.Id}&group=${imageRequest.group}`,
          {
            method: 'POST',
            mode: 'cors',
            cache: 'no-cache',
            credentials: 'same-origin',
            headers: {
              Authorization: `Bearer ${UserHelper.currentUser.Token}`,
            },
            body: formData,
          },
        )
          .then((response) => {
            return response.json();
          })
          .then((response: any) => {
            // const group = imageRequest.group;
            // if (group.includes('eventPersonnel_')) {
            //   var guid = group.replace('eventPersonnel_', '');

            //   const personnel = linq
            //     .from(event.PersonnelGroups)
            //     .selectMany((pg) => pg.Personnel)
            //     .firstOrDefault((p) => p.Guid == guid);

            //   if (personnel) {
            //     personnel.ImageUrl = response.url;
            //   }
            // } else

            if (imageRequest.group == ImageRequestGroup.Event) {
              event.ImageUrl = response.url;
            } else if (imageRequest.group == ImageRequestGroup.Organisation) {
              event.Organisation.LogoUrl = response.url;
            }
          });
      }),
    ).then(() => {
      return event;
    });
  }

  public static getDefault(): IEvent {
    const sp = SeatingPlanHelper.getDefaultSeatingPlan();

    return {
      Unlisted: false,
      IncludeQRCodeInEmail: false,
      DisableTheme: true,
      HandlingFee: null,
      HandlingFeePercentage: null,
      PrivatePasswordRequired: false,
      Id: 0,
      UseSeatingPlan: false,
      CollectAtBoxOffice: false,
      ExtraSaleMinutes: 0,
      OnlineFrom: moment().format('YYYY-MM-DD HH:mm'),
      Dates: [],
      PersonnelGroups: [],
      UnallocatedCategoryGroups: [],
      AllocatedCategoryGroups: [],
      Discounts: [],
      SeatingPlans: [sp],
      Venue: VenueHelper.getDefault(),
      Organisation: {
        ...OrganisationHelper.getDefault(),
        Users: [UserHelper.currentUser],
        Roles: [],
        MemberTypes: [
          {
            Id: null,
            Name: 'Member',
            Quota: 0,
            Default: true,
          },
        ],
      },
      CustomAdmissionType: '',
      AbsorbFee: false,
      AllowMarketing: false,
      AdmissionType: AdmissionTypes.PrintedAndMobile,
      AllowSurvey: false,
      Categories: [],
      EventTag: '',
      Name: '',
      Description: '',
      MembershipsEnabled: true,
      Questions: [],
      RequestingEnabled: false,
      RequestingPasswordEnabled: false,
      RequestingPassword: '',
      CurrencySymbol: '£',
      Private: false,
      UseUnallocatedTickets: false,
      PreventSingleSeatsEnabled: true,
      ShowBarcodes: true,
      UseThreeWordAddress: false,
      TimeZoneId: 'GMT Standard Time',
      PrivatePassword: '',
      ReferralsEnabled: false,
      ThreeWordAddresses: [],
      Theme: ThemeHelper.getDefaultTheme(),
      ShowDiscountCode: false,
      ShowMap: false,
      TermsAndConditions: '',
      OnlineFromDate: moment().format('YYYY-MM-DD HH:mm'),
      EventDateIds: [],
      Referrals: false,
    };
  }

  public static getFutureDates(e: IEvent, isAdmin: boolean): IEventDate[] {
    return linq
      .from(e.Dates)
      .where((ed) => (isAdmin || !ed.Suspend) && moment(ed.DateAsString).add(12, 'hours') > moment())
      .orderBy((ed) => moment(ed.DateAsString))
      .groupBy((ed) => moment(ed.DateAsString).format('dddd Do MMM YYYY'))
      .toArray();
  }

  public static getPastDates(e: IEvent, isAdmin: boolean): IEventDate[] {
    return linq
      .from(e.Dates)
      .where((ed) => (isAdmin || !ed.Suspend) && moment(ed.DateAsString).add(12, 'hours') <= moment())
      .orderBy((ed) => moment(ed.DateAsString))
      .groupBy((ed) => moment(ed.DateAsString).format('dddd Do MMM YYYY'))
      .toArray();
  }

  public static getAvailableDates(event: IEvent, isAdmin: boolean): IAvailableDatesModel {
    var dates =
      event &&
      linq
        .from(event.Dates)
        .where((ed) => isAdmin || !ed.Suspend)
        .where((ed) => !ed.External)
        .orderBy((ed) => moment(ed.DateAsString));

    const minDate = event && moment.utc(dates.count() == 0 ? 0 : dates.min((d) => moment(d.DateAsString).valueOf()));
    const maxDate = event && moment.utc(dates.count() == 0 ? 0 : dates.max((d) => moment(d.DateAsString).valueOf()));

    return {
      visibleDates: dates,
      datesFromToString:
        dates.count() == 0
          ? 'No dates available'
          : minDate.format('DD/MM/YY HH:mm') === maxDate.format('DD/MM/YY HH:mm')
            ? DateHelper.asDateAtTimeAmPmFromMoment(minDate)
            : minDate.format('DD/MM/YY') === maxDate.format('DD/MM/YY')
              ? minDate.format('ddd Do MMM, YYYY') + ' (Multiple Times)'
              : (minDate.year == maxDate.year ? minDate.format('ddd Do MMM') : minDate.format('ddd Do MMM, YYYY')) +
                ' - ' +
                maxDate.format('ddd Do MMM, YYYY'),
    };
  }
}
