import linq from 'linq';
import moment from 'moment';
import { FunctionComponent, useEffect, useState } from 'react';
import GeneralApi from '../../api/GeneralApi';
import Block from '../../components/Block';
import BlockHeader from '../../components/BlockHeader';
import BlockInfo, { InfoType } from '../../components/BlockInfo';
import BlockTextBox from '../../components/BlockTextBox';
import Button from '../../components/Button';
import CheckBox from '../../components/CheckBox';
import Loader from '../../components/Loader';
import Lookup from '../../components/Lookup';
import OrderQuestions from '../../components/OrderQuestions';
import Radio from '../../components/Radio';
import SpacerTable from '../../components/SpacerTable';
import { DiscountTypes } from '../../enums/DiscountTypes';
import { PaymentMethods } from '../../enums/PaymentMethods';
import { QuestionTypes } from '../../enums/QuestionTypes';
import AttendeeHelper from '../../helpers/AttendeeHelper';
import CurrencyHelper from '../../helpers/CurrencyHelper';
import DateHelper from '../../helpers/DateHelper';
import DiscountHelper from '../../helpers/DiscountHelper';
import GAHelper from '../../helpers/GAHelper';
import OrderHelper from '../../helpers/OrderHelper';
import ThemeHelper from '../../helpers/ThemeHelper';
import UserHelper from '../../helpers/UserHelper';
import { IDiscount } from '../../interfaces/IDiscount';
import { IEvent } from '../../interfaces/IEvent';
import { IEventDate } from '../../interfaces/IEventDate';
import { IOption } from '../../interfaces/IOption';
import { IPurchase } from '../../interfaces/IPurchase';
import { ISeatCategory } from '../../interfaces/ISeatCategory';
import { ITheme } from '../../interfaces/ITheme';
import { ITicket } from '../../interfaces/ITicket';
import { ITicketCategory } from '../../interfaces/ITicketCategory';
import { IUser } from '../../interfaces/IUser';
import { PaymentGateway } from '../../modals/OrderModal';
import DiscountService from '../../services/DiscountService';
import TicketService, { ITicketAvailability } from '../../services/TicketService';
import { AdmissionTypes } from '../Editor/event/TicketSetupSection';
import './Order.scss';
import DiscountSection from './Sections/DiscountSection';
import SummarySection from './Sections/SummarySection';
import OrderTicketSection from './Sections/OrderTicketSection';
import StripeCheckout from '../../components/stripe/StripeCheckout';
import EmailHelper from '../../helpers/EmailHelper';
import { IOrganisation } from '../../interfaces/IOrganisation';
import PixelHelper from '../../helpers/PixelHelper';
import { DiscountModes } from '../../enums/DiscountModes';
import { useRecoilState } from 'recoil';
import eventReferralState from '../../atoms/eventReferralState';

enum Stages {
  OrderSetup = 1,
  EnhancedRefundService = 2,
  CardPayment = 3,
  Completed = 4,
}

export interface IOrderConfig {
  user: IUser;
  event: IEvent;
  eventDate: IEventDate;
  tickets: Array<ITicket>;
}

interface IProps {
  theme: ITheme;
  organisation: IOrganisation;
  orderConfig: IOrderConfig;
  onOrderCompleted: (orderId: number) => void;
  backClicked: () => void;
  seatCategories: Array<ISeatCategory>;
  ticketCategories: Array<ITicketCategory>;
  stripeConnectAccountId: string;
  isAdmin: boolean;
  isMember: boolean;
  lockTickets: (tickets) => Promise<any>;
  paymentMethod?: PaymentMethods;
  gateway: PaymentGateway;
}

let attendeeTimeout: number = 0;

const getDefaultTickets = (orderConfig: IOrderConfig, isAdmin: boolean) => {
  return orderConfig.tickets.map((ticket) => {
    if (ticket.GeneralAdmission) return ticket;
    ticket.TicketCategories = linq
      .from(ticket.TicketCategories)
      .orderBy((tc) => tc.GroupIndex)
      .toArray();

    let tc = linq
      .from(ticket.TicketCategories)
      .orderBy((tc) => tc.GroupIndex)
      .firstOrDefault((tc) => !tc.Hide || isAdmin);

    if (!tc) {
      tc = linq.from(ticket.TicketCategories).orderBy((tc) => tc.GroupIndex);
    }

    ticket.TicketCategory = tc;
    ticket.TicketCategoryId = tc.Id;

    return ticket;
  });
};

const OrderComponent: FunctionComponent<IProps> = (props) => {
  const gateway = props.gateway;
  const [orderConfig, setOrderConfig] = useState<IOrderConfig>(props.orderConfig);

  const user = orderConfig.user;
  const event = orderConfig.event;
  const eventDate = orderConfig.eventDate;
  const isAdmin = props.isAdmin;

  const [tickets, setTickets] = useState(() => getDefaultTickets(orderConfig, isAdmin));

  const [ticketAvailability, setTicketAvailability] = useState<ITicketAvailability>(null);

  const [busy, setBusy] = useState<string>(null);

  const [eventReferral, setEventReferral] = useRecoilState(eventReferralState);
  const referral = eventReferral && eventReferral[event.Id];
  console.log('eventReferral', eventReferral);

  const [orderId, setOrderId] = useState<number>(0);
  const [discountCodeError, setDiscountCodeError] = useState<string>('');
  const [discountCode, setDiscountCode] = useState<string>('');
  const [addUserNote, setAddUserNote] = useState<boolean>(false);
  const [userNotes, setUserNotes] = useState<string>('');
  const [addAdminNote, setAddAdminNote] = useState<boolean>(false);
  const [adminNotes, setAdminNotes] = useState<string>('');

  const [requestPassword, setRequestPassword] = useState<string>('');
  const [attendeeEmail, setAttendeeEmail] = useState<string>('');
  const [attendeeEmailConfirmation, setAttendeeEmailConfirmation] = useState<string>('');
  const [attendeeName, setAttendeeName] = useState<string>('');
  const [orderError, setOrderError] = useState<string>('');
  const [eventDateFormatted, setEventDateFormatted] = useState<string>('');
  const [ticketGuid, setTicketGuid] = useState<string>('');

  const [showOrganisationTermsAndConditions, setShowOrganisationTermsAndConditions] = useState<boolean>(false);
  const [acceptedSeatyTerms, setAcceptedSeatyTerms] = useState<boolean>(false);
  const [acceptedMarketing, setAcceptedMarketing] = useState<boolean>(false);
  const [collectAtBoxOffice, setCollectAtBoxOffice] = useState<boolean>(false);
  const [acceptedSurvey, setAcceptedSurvey] = useState<boolean>(false);

  const [attendeeOptions, setAttendeeOptions] = useState<Array<IOption>>([]);
  const [availableDiscounts, setAvailableDiscounts] = useState<Array<IDiscount>>(
    event.Discounts ? event.Discounts : [],
  );
  const [validDiscounts, setValidDiscounts] = useState<Array<IDiscount>>([]);

  const [_activeDiscount, setActiveDiscount] = useState<IDiscount>(null);
  const enterDiscountCodeSelected = _activeDiscount && _activeDiscount.Id == -1;
  const activeDiscount = _activeDiscount && _activeDiscount.Id == -1 ? null : _activeDiscount;

  const [loadingDiscountCode, setLoadingDiscountCode] = useState(false);

  const [_selectedPaymentMethod, setSelectedPaymentMethod] = useState<PaymentMethods>(
    props.paymentMethod ? props.paymentMethod : PaymentMethods.NoPayment,
  );

  const [stage, setStage] = useState<Stages>(Stages.OrderSetup);
  const [enhancedRefundService, setEnhancedRefundService] = useState<boolean>(false);
  const isOnlinePayment = _selectedPaymentMethod == PaymentMethods.CardSeaty;

  const allowRequests = !isAdmin && event.RequestingEnabled && user;

  const summary = OrderHelper.getOrderSummary(
    isOnlinePayment,
    tickets,
    event.AbsorbFee,
    event.CardFeeMinimum,
    event.CardFeePercentage,
    activeDiscount,
    event.HandlingFee,
    event.HandlingFeePercentage,
    event.Organisation.ServiceFee,
    enhancedRefundService,
  );

  const selectedPaymentMethod = props.paymentMethod
    ? props.paymentMethod
    : summary.total === 0
      ? PaymentMethods.NoPayment
      : _selectedPaymentMethod;

  interface ISubmitOrderResponse {
    success?: boolean;
    Purchase?: IPurchase;
    Id: number;
    GuidString: string;
    ErrorMessage?: string;
    AdditionalActionRequired: boolean;
  }

  const scrollToTop = () => {
    const element = document.querySelector('.seaty-modal');
    if (element) element.scrollTop = 0;
  };

  const onOrderCompleted = (result: ISubmitOrderResponse): Promise<any> => {
    setOrderId(result.Id);
    setTicketGuid(result.GuidString);
    setStage(Stages.Completed);
    setBusy(null);
    props.onOrderCompleted(result.Id);
    GAHelper.event('order', 'completed', tickets.length);

    if (_selectedPaymentMethod === PaymentMethods.CardSeaty) {
      const purchaseDetails = {
        content_name: `${event.Name}, ${event.Venue.Name}, ${eventDateFormatted}`,
        num_items: summary.ticketQuantity,
        currency: 'GBP',
        value: summary.total / 100.0,
        isAdmin: isAdmin,
        contents: tickets.map((ticket: ITicket) => {
          if (!ticket.SeatCategory)
            return {
              id: `${ticket.Quantity} x ${ticket.TicketCategory.Name}`,
              quantity: ticket.Quantity,
              value: ticket.TicketCategory.PriceAsInt / 100.0,
              currency: 'GBP',
            };

          return {
            id: `${ticket.Group}${ticket.Name} ${ticket.SeatCategory.Name} ${ticket.TicketCategory.Name} `,
            quantity: ticket.Quantity,
            value: ticket.TicketCategory.PriceAsInt / 100.0,
            currency: 'GBP',
          };
        }),
      };

      if (event.FacebookPixelId) PixelHelper.trackSingle(event.FacebookPixelId, 'Purchase', purchaseDetails);
      if (event.Organisation && event.Organisation.FacebookPixelId)
        PixelHelper.trackSingle(event.Organisation.FacebookPixelId, 'Purchase', purchaseDetails);
      if (event.Tour && event.Tour.FacebookPixelId)
        PixelHelper.trackSingle(event.Tour.FacebookPixelId, 'Purchase', purchaseDetails);
    }

    scrollToTop();

    return Promise.resolve(result);
  };

  const [questionAnswers, setQuestionAnswers] = useState([]);

  useEffect(() => {
    setEventDateFormatted(eventDate && `${DateHelper.asDateAtTimeAmPm(eventDate.DateAsString)}`);
    setDiscountCodeError('');
    setDiscountCode('');
    setOrderError(null);
    setAcceptedSeatyTerms(false);

    setAttendeeEmail(isAdmin ? '' : user == null ? '' : user.Email);
    setAttendeeEmailConfirmation(isAdmin ? '' : user == null ? '' : user.Email);
    setUserNotes('');
    setAdminNotes('');
    setAttendeeName(isAdmin ? '' : user == null ? '' : user.Name);

    setShowOrganisationTermsAndConditions(false);
    setAcceptedMarketing(false);
    setAcceptedSurvey(false);
    setBusy(null);

    setAttendeeOptions([]);
    setValidDiscounts([]);

    setActiveDiscount(null);

    isAdmin ? setSelectedPaymentMethod(PaymentMethods.NoPayment) : setSelectedPaymentMethod(PaymentMethods.CardSeaty);

    setStage(Stages.OrderSetup);

    refreshActiveDiscounts();

    window.setTimeout(() => {
      const modal = document.querySelector('.seaty-modal');
      const dropdown: HTMLDivElement = modal && modal.querySelector('.dropdown');
      if (dropdown) dropdown.focus();
    }, 200);

    if (selectedPaymentMethod === PaymentMethods.CardSeaty) {
      const purchaseDetails = {
        content_name: `${event.Name}, ${event.Venue.Name}, ${eventDateFormatted}`,
        num_items: summary.ticketQuantity,
        currency: 'GBP',
        value: summary.total / 100.0,
        isAdmin: isAdmin,
        contents: tickets.map((ticket: ITicket) => {
          if (!ticket.SeatCategory)
            return {
              id: `${ticket.Quantity} x ${ticket.TicketCategory.Name}`,
              quantity: ticket.Quantity,
              value: ticket.TicketCategory.PriceAsInt / 100.0,
              currency: 'GBP',
            };

          return {
            id: `${ticket.Group}${ticket.Name} ${ticket.SeatCategory.Name} ${ticket.TicketCategory.Name} `,
            quantity: ticket.Quantity,
            value: ticket.TicketCategory.PriceAsInt / 100.0,
            currency: 'GBP',
          };
        }),
      };

      if (event.FacebookPixelId) PixelHelper.trackSingle(event.FacebookPixelId, 'AddToCart', purchaseDetails);
      if (event.Organisation && event.Organisation.FacebookPixelId) {
        PixelHelper.trackSingle(event.Organisation.FacebookPixelId, 'AddToCart', purchaseDetails);
      }
      if (event.Tour && event.Tour.FacebookPixelId)
        PixelHelper.trackSingle(event.Tour.FacebookPixelId, 'AddToCart', purchaseDetails);
    }
  }, [orderConfig]);

  const refreshActiveDiscounts = (discounts?) => {
    const useDiscounts = discounts ? discounts : availableDiscounts;

    let validDiscounts = DiscountService.getValidDiscountsForTickets(event, useDiscounts, tickets);
    let activeDiscount = DiscountHelper.getBestAutomaticDiscount(validDiscounts);

    setValidDiscounts(validDiscounts);
    setActiveDiscount(activeDiscount);
  };

  const [lookingForAttendees, setLookingForAttendees] = useState(false);
  const handleAttendeeEmailLookupTextChanged = (lookupText: string) => {
    setAttendeeEmail(lookupText);
    window.clearTimeout(attendeeTimeout);

    if (lookupText.length > 0) {
      setLookingForAttendees(true);
      attendeeTimeout = window.setTimeout(() => {
        AttendeeHelper.lookupAttendees(lookupText).then((options) => {
          setAttendeeOptions(options);
          setLookingForAttendees(false);
        });
      }, 500);
    } else {
      setLookingForAttendees(false);
    }
  };

  const continueToCardPayment = () => {
    setTicketAvailability(null);

    setBusy('Getting ticket availability...');

    props
      .lockTickets(orderConfig.tickets)
      .then((availability: ITicketAvailability) => {
        setStage(Stages.CardPayment);

        availability.SessionId = UserHelper.getSessionId();
        availability.Email = attendeeEmail;

        if (!availability.AllAvailable) {
          setOrderError(availability.ErrorMessage);
          setBusy(null);

          window.setTimeout(() => {
            const element = document.getElementById('card-details');
            if (element) {
              element.scrollIntoView();
            }
          }, 500);

          return Promise.resolve();
        }

        setTicketAvailability(availability);

        if (gateway == PaymentGateway.Pay) {
          GeneralApi.request('POST', '/api/PayInitialisePurchase', {
            PaymentMethodId: '',
            PaymentIntentId: '',
            Purchase: {
              PaymentType: _selectedPaymentMethod,
              Id: availability.Id,
              EventId: availability.EventId,
              AllAvailable: availability.AllAvailable,
              EventDateId: eventDate.Id,
              Email: attendeeEmail,
              Notes: userNotes,
              AdminNotes: adminNotes,
              QuestionAnswers: questionAnswers,
              Seats: availability.Tickets,
              Discount: activeDiscount,
              DiscountCode: discountCode,
              SessionId: UserHelper.getSessionId(),
              AttendeeName: attendeeName,
              ReferralName: '',
              AllowMarketing: acceptedMarketing,
              AllowSurvey: acceptedSurvey,
              CollectAtBoxOffice: collectAtBoxOffice,
            },
          }).then((response: { success: boolean; Purchase: IPurchase }) => {
            setBusy(null);
          });
        } else {
          setBusy(null);
        }
      })
      .catch((err) => {
        setOrderError('Tickets no longer available.');
      });
  };

  const handleSubmitDiscountCodeClick = () => {
    if (!discountCode || discountCode.length == 0) {
      setDiscountCodeError('Please enter a discount code.');
      return;
    }

    setDiscountCodeError('');
    setLoadingDiscountCode(true);

    DiscountService.checkDiscountCode(event.Id, discountCode)
      .then((discount) => {
        const existingDiscount = linq.from(availableDiscounts).firstOrDefault((d) => d.Id == discount.Id);

        const validDiscounts = DiscountService.getValidDiscountsForTickets(
          event,
          [...availableDiscounts, discount],
          tickets,
        );
        const isNewDiscountValid = validDiscounts.filter((d) => d.Id == discount.Id).length > 0;

        if (!isNewDiscountValid) {
          setDiscountCodeError(`Discount "${discount.Name}" is not valid for this order.`);
        } else {
          if (isAdmin && discount.ApplicationMode == DiscountModes.SingleUseDiscountCodes && existingDiscount) {
            existingDiscount.Activated = true;
            existingDiscount.Codes = [...discount.Codes];
            setActiveDiscount(existingDiscount);
          }
          if (existingDiscount == null) {
            const newDiscounts = [...availableDiscounts, discount];
            setAvailableDiscounts(newDiscounts);
            refreshActiveDiscounts(newDiscounts);
            setActiveDiscount(discount);
          }
        }

        setStage(Stages.OrderSetup);
      })
      .catch((error) => {
        setDiscountCodeError(`No valid discount code found for "${discountCode}".`);
      })
      .finally(() => {
        setLoadingDiscountCode(false);
      });
  };

  const renderNotesSection = () => {
    return (
      <>
        <div className="spacer" />
        {!addUserNote && (
          <>
            <SpacerTable>
              <Button
                className="small spin-trail"
                onExecute={() => {
                  setAddUserNote(true);
                }}
                text={!isAdmin ? `Add note to self` : `Add note on order`}
              />
            </SpacerTable>
          </>
        )}
        {addUserNote && (
          <>
            <table className="blocks">
              <tbody>
                <Block>
                  <BlockHeader rightText={'(Optional)'}>{isAdmin ? `Note on order` : `Note to yourself`}</BlockHeader>
                  <BlockTextBox
                    autoFocus
                    value={userNotes}
                    onChange={(e) => {
                      setUserNotes(e);
                    }}
                    rows={1}
                  />
                </Block>
                {isAdmin ? (
                  <BlockInfo>
                    Notes on an order will appear on the ticket summary page and attendees can see / edit them. The
                    attendee will not be given specific notification of the notes being added.
                  </BlockInfo>
                ) : (
                  <BlockInfo>
                    Notes to yourself will appear on your order summary. They are optional and are not monitored by
                    event staff. If you need to give extra information to the event organiser, please contact them
                    directly.
                  </BlockInfo>
                )}
              </tbody>
            </table>
          </>
        )}
        {isAdmin && !addAdminNote && (
          <>
            <div className="spacer" />
            <SpacerTable>
              <Button
                className="small"
                onExecute={() => {
                  setAddAdminNote(true);
                }}
                text={`Add admin only note`}
              />
            </SpacerTable>
          </>
        )}
        {isAdmin && addAdminNote && (
          <>
            <div className="spacer" />
            <table className="blocks">
              <tbody>
                <Block>
                  <BlockHeader rightText={'(Optional)'}>Notes for admins</BlockHeader>
                  <BlockTextBox
                    autoFocus
                    value={adminNotes}
                    onChange={(e) => {
                      setAdminNotes(e);
                    }}
                    rows={1}
                  />
                </Block>
                <BlockInfo>These notes are optional and visable to event administrators only.</BlockInfo>
              </tbody>
            </table>
          </>
        )}
      </>
    );
  };

  const renderDiscountCodeSection = () => {
    if (!event.ShowDiscountCode) return null;

    const activeDiscountMatchesCurrentCode =
      activeDiscount && activeDiscount.Code?.toUpperCase() == discountCode.toUpperCase();
    return (
      <>
        <div className="spacer" />

        {isAdmin && (
          <>
            <table className="blocks">
              <tbody>
                <DiscountSection
                  enterDiscountCodeSelected={enterDiscountCodeSelected}
                  event={event}
                  setActiveDiscount={setActiveDiscount}
                  activeDiscount={activeDiscount}
                  isAdmin={isAdmin}
                  validDiscounts={validDiscounts}
                />
              </tbody>
            </table>
          </>
        )}

        {activeDiscountMatchesCurrentCode && discountCode ? (
          <>
            <SpacerTable>
              <Button
                disabled={!discountCode || discountCode.length == 0 || loadingDiscountCode}
                style={{ margin: 0 }}
                className="small"
                onExecute={() => {
                  const newDiscounts = linq.from(availableDiscounts).where((d) => d.Id !== activeDiscount.Id);
                  setAvailableDiscounts(newDiscounts);
                  setActiveDiscount(null);
                  setDiscountCode('');
                  refreshActiveDiscounts(newDiscounts);
                }}
                text={`Clear discount code`}
              />
            </SpacerTable>
          </>
        ) : (
          (!isAdmin || enterDiscountCodeSelected) && (
            <>
              <table className="blocks">
                <tbody>
                  <Block>
                    <BlockHeader>Have a discount code?</BlockHeader>

                    <div className="row">
                      <div className="col-sm-9">
                        <BlockTextBox
                          disabled={loadingDiscountCode || (!isAdmin && activeDiscount != null)}
                          placeholder="Discount code"
                          autoComplete="off"
                          autoFocus={isAdmin}
                          id="discountCode"
                          value={discountCode}
                          onChange={(t) => {
                            setDiscountCode(t.toUpperCase());
                            setDiscountCodeError(null);
                          }}
                          onKeyPress={(e) => {
                            var ENTER = 13;
                            if (
                              e.charCode == ENTER &&
                              !(!discountCode || discountCode.length == 0 || loadingDiscountCode)
                            ) {
                              e.preventDefault();
                              handleSubmitDiscountCodeClick();
                            }
                          }}
                        />
                      </div>
                      <div className="col-sm-3" style={{ paddingTop: '5px' }}>
                        {activeDiscount && !isAdmin ? (
                          <Button
                            style={{ margin: 0 }}
                            className="small"
                            onExecute={() => {
                              setAvailableDiscounts(
                                availableDiscounts.filter(
                                  (d) =>
                                    d.Code != discountCode &&
                                    (d.Codes ? d.Codes.filter((c) => c.Code == discountCode).length == 0 : true),
                                ),
                              );
                              setActiveDiscount(null);
                              setDiscountCode('');
                            }}
                            text="Clear"
                          />
                        ) : (
                          <Button
                            disabled={!discountCode || discountCode.length == 0 || loadingDiscountCode}
                            style={{ margin: 0 }}
                            className="small"
                            onExecute={handleSubmitDiscountCodeClick}
                            text="Apply"
                          />
                        )}
                      </div>
                    </div>
                  </Block>

                  {discountCodeError != null && discountCodeError.length > 0 ? (
                    <BlockInfo type={InfoType.Error}>{discountCodeError}</BlockInfo>
                  ) : null}
                </tbody>
              </table>
            </>
          )
        )}
      </>
    );
  };

  const submitOrder = () => {
    if (!user && attendeeEmail.toLowerCase() !== attendeeEmailConfirmation.toLowerCase()) {
      setOrderError('Email address does not match the email address confirmation.');
      return;
    }

    setBusy('Submitting order...');

    TicketService.getAvailability(
      event.Id,
      eventDate.Id,
      tickets.map((t: ITicket) => {
        if (t.TicketCategory) {
          t.TicketCategoryId = t.TicketCategory.Id;
        } else {
          t.TicketCategoryId = t.TicketCategories[0].Id;
        }
        return t;
      }),
    )
      .then((availabilityResult: ITicketAvailability) => {
        availabilityResult.SessionId = UserHelper.getSessionId();
        availabilityResult.Email = attendeeEmail;

        if (!availabilityResult.AllAvailable) {
          setOrderError(availabilityResult.ErrorMessage);
          setBusy(null);
          return Promise.resolve();
        }

        return processOrder(availabilityResult).then(onOrderCompleted);
      })
      .catch((message: string) => {
        setOrderError(message);
        setBusy(null);
      });
  };

  const processOrder = (availability: ITicketAvailability) => {
    if (selectedPaymentMethod == PaymentMethods.CardSeaty) {
      // return processStripeCharge(availability);
    } else if (selectedPaymentMethod == PaymentMethods.Request) {
      return processTicketRequest(availability);
    } else {
      return processBoxOfficeOrder(availability);
    }
  };

  const processTicketRequest = (availability: ITicketAvailability): Promise<any> => {
    return GeneralApi.request('PUT', '/api/Request', {
      EventDateId: eventDate.Id,
      Email: attendeeEmail,
      Notes: userNotes,
      AdminNotes: adminNotes,
      QuestionAnswers: questionAnswers,
      Seats: availability.Tickets,
      ReferralName: '',
      RequestingPassword: requestPassword,
      Discount: activeDiscount,
      DiscountCode: discountCode,
      SessionId: UserHelper.getSessionId(),
      AllowMarketing: acceptedMarketing,
      AllowSurvey: acceptedSurvey,
      CollectAtBoxOffice: collectAtBoxOffice,
    });
  };

  const processBoxOfficeOrder = (availability: ITicketAvailability): Promise<any> => {
    return GeneralApi.request('PUT', '/api/Order', {
      EventId: availability.EventId,
      AllAvailable: availability.AllAvailable,
      EventDateId: eventDate.Id,
      Email: attendeeEmail,
      Notes: userNotes,
      AdminNotes: adminNotes,
      QuestionAnswers: questionAnswers,
      Seats: availability.Tickets,
      PaymentTaken: selectedPaymentMethod != PaymentMethods.NoPayment,
      Discount: activeDiscount,
      DiscountCode: discountCode,
      PaymentType: selectedPaymentMethod,
      SessionId: UserHelper.getSessionId(),
      AttendeeName: attendeeName,
      ReferralName: '',
      AllowMarketing: acceptedMarketing,
      AllowSurvey: acceptedSurvey,
      CollectAtBoxOffice: collectAtBoxOffice,
    });
  };

  const renderCompletedSection = () => {
    if (stage != Stages.Completed) {
      return null;
    }

    const ticketLink = `/Order/Tickets/${ticketGuid}`;

    return (
      <div className="spacer">
        {isAdmin ? (
          <SpacerTable>
            <h1>All done!</h1>
            You have created an order as a box office representative of {event.Organisation.Name}.
          </SpacerTable>
        ) : selectedPaymentMethod == PaymentMethods.Request ? (
          ticketGuid && ticketGuid.length > 0 ? (
            <SpacerTable>
              <h1>Request Approved!</h1>
              Your request has been automatically approved. You can now go to your tickets. We have sent you a
              confirmation email.
            </SpacerTable>
          ) : (
            <SpacerTable>
              <h1>Request Submitted!</h1>
              Your request has been completed. You will receive a response from {event.Organisation.Name} when they have
              reviewed it.
            </SpacerTable>
          )
        ) : (
          <SpacerTable>
            <h1>Thank you!</h1>
            Your order has been completed, you can now go to your tickets. We have sent you a confirmation email.
          </SpacerTable>
        )}

        <div className="spacer" />

        {ticketLink && ticketGuid && ticketGuid.length > 0 && (
          <>
            <SpacerTable>
              <a href={ticketLink} className="button confirm large">
                View Tickets
              </a>
            </SpacerTable>
            <div className="spacer" />
          </>
        )}

        <SummarySection
          orderAnswers={questionAnswers}
          eventDateFormatted={eventDateFormatted}
          activeDiscount={activeDiscount}
          orderId={orderId}
          attendeeName={isAdmin && attendeeName.length == 0 ? user.Name : attendeeName}
          attendeeEmail={attendeeEmail}
          paymentMethod={selectedPaymentMethod}
          tickets={tickets}
          event={event}
          organisation={event.Organisation}
          venue={event.Venue}
          seatCategories={props.seatCategories}
          ticketCategories={props.ticketCategories}
          currency={event.CurrencySymbol}
          publicDiscounts={availableDiscounts}
        />

        <div className="spacer" />

        <SpacerTable>
          <a
            onClick={() => {
              props.backClicked();
            }}
            className="button large"
          >
            Back to Event
          </a>
        </SpacerTable>
      </div>
    );
  };

  const getAttendeeEmail = () => {
    let value = attendeeEmail;
    if (value.length == 0 && user) {
      value = user.Email;
    }
    return value;
  };

  const getAttendeeName = () => {
    let value = attendeeName;
    if (value.length == 0 && user) {
      value = user.Name;
    }
    return value;
  };

  const attendeeDetailsValid = () => {
    if (selectedPaymentMethod == PaymentMethods.CardSeaty) return true;

    if (
      !user &&
      (attendeeEmailConfirmation.length == 0 || attendeeEmailConfirmation.toLowerCase() !== attendeeEmail.toLowerCase())
    )
      return false;
    return EmailHelper.validate(getAttendeeEmail()) && acceptedSeatyTerms && getAttendeeName().length > 2;
  };

  let paymentOptions: Array<IOption> = [];

  if (isAdmin) {
    paymentOptions = [
      { Text: 'Not taken', Id: PaymentMethods.NoPayment },
      {
        Text: 'Online card payment',
        Id: PaymentMethods.CardSeaty,
      },
      { Text: 'Paid with cash', Id: PaymentMethods.Cash },
      { Text: 'Paid with cheque', Id: PaymentMethods.Cheque },
      {
        Text: 'Paid by bank transfer',
        Id: PaymentMethods.BankTransfer,
      },
      {
        Text: 'Paid with card outside of Seaty',
        Id: PaymentMethods.CardOutSideSeaty,
      },
    ];
  } else {
    paymentOptions = [
      {
        Text: 'Credit or Debit Card',
        Id: PaymentMethods.CardSeaty,
        TextRight: '(Instant delivery)',
      },
    ];

    if (allowRequests) {
      paymentOptions.push({
        Text: 'Make a ticket request',
        TextRight: '(Requires approval)',
        Id: PaymentMethods.Request,
      });
    }

    if (summary.total === 0) {
      paymentOptions = [{ Text: 'Free tickets', Id: PaymentMethods.NoPayment }];
    }
  }

  const renderAttendeeDetailsSection = () => {
    if (selectedPaymentMethod == PaymentMethods.CardSeaty) return null;

    return (
      <>
        <div className="spacer-x2" />
        <SpacerTable>{isAdmin ? <h1>Attendee details</h1> : <h1>Your details</h1>}</SpacerTable>

        <div className="spacer" />

        <table className="blocks">
          <tbody>
            {isAdmin ? (
              <>
                <Block>
                  <BlockHeader rightText={'(Required)'}>Full Name</BlockHeader>
                  <BlockTextBox
                    autoComplete="off"
                    placeholder={user.Name}
                    id="attendeeName"
                    value={attendeeName}
                    onChange={(t) => setAttendeeName(t)}
                  />
                  <div className="spacer-x05" />
                  <Lookup
                    autoComplete="off"
                    placeholder={user.Email}
                    type="email"
                    name="emailaddress"
                    value={attendeeEmail}
                    options={attendeeOptions}
                    loading={lookingForAttendees}
                    onLookupOptionSelected={(option) => {
                      setAttendeeName(option.Header);
                      setAttendeeEmail(option.Text);
                    }}
                    onLookupTextChange={handleAttendeeEmailLookupTextChanged}
                    header="Email Address"
                    rightText={'(Required)'}
                  />
                </Block>
                <BlockInfo type={InfoType.Info}>
                  A booking confirmation with a link to the tickets will be sent to this email address.
                </BlockInfo>
              </>
            ) : user ? (
              <BlockInfo type={InfoType.Info}>
                Your name and email address will be taken from your user account ({UserHelper.currentUser.Email}).
              </BlockInfo>
            ) : (
              <>
                <Block>
                  <BlockHeader rightText={'(Required)'}>Full Name</BlockHeader>
                  <BlockTextBox id="attendeeName" value={attendeeName} onChange={(t) => setAttendeeName(t)} />
                  <div className="spacer-x05" />
                  <BlockHeader rightText={'(Required)'}>Email</BlockHeader>
                  <BlockTextBox
                    type="email"
                    autoComplete="off"
                    id="emailaddress"
                    value={attendeeEmail}
                    onChange={(t) => setAttendeeEmail(t)}
                  />
                  <div className="spacer-x05" />
                  <BlockHeader rightText={'(Required)'}>Confirm Email</BlockHeader>
                  <BlockTextBox
                    type="email"
                    autoComplete="off"
                    id="emailConfirmation"
                    value={attendeeEmailConfirmation}
                    onChange={(t) => setAttendeeEmailConfirmation(t)}
                  />
                </Block>
                {attendeeEmail.length > 0 &&
                attendeeEmailConfirmation.length > 0 &&
                attendeeEmail.toLowerCase() !== attendeeEmailConfirmation.toLowerCase() ? (
                  <BlockInfo type={InfoType.Warning}>
                    Email confirmation does not match the email address entered. Please enter matching email addresses
                    so we can ensure we sent the tickets to the correct person.
                  </BlockInfo>
                ) : (
                  <BlockInfo type={InfoType.Info}>
                    A booking confirmation with a link to your tickets will be sent to the above email address.
                  </BlockInfo>
                )}
              </>
            )}
          </tbody>
        </table>
      </>
    );
  };

  const renderShortSummarySection = () => {
    const totalPriceFormatted = CurrencyHelper.formatCurrency(event.CurrencySymbol, summary.total);
    let handlingFee = 0;

    if (isOnlinePayment) {
      if (event.HandlingFee && event.HandlingFee > 0) {
        handlingFee += event.HandlingFee;
      }
      if (event.HandlingFeePercentage && event.HandlingFeePercentage > 0) {
        handlingFee += summary.total * (event.HandlingFeePercentage / 100.0);
      }
    }

    return (
      <Block className="tickets-totals">
        <table>
          <tbody>
            {(activeDiscount || summary.allFees > 0) && (
              <tr>
                <td className="regular-text-color">
                  <strong>Sub Total</strong>
                </td>
                <td className="regular-text-color right">
                  <strong>{CurrencyHelper.formatCurrency(event.CurrencySymbol, summary.subTotal)}</strong>
                </td>
              </tr>
            )}
            {activeDiscount && (
              <tr>
                <td className="regular-text-color">
                  <strong>
                    {activeDiscount.Name}
                    {activeDiscount.DiscountType === DiscountTypes.FixedAmountOffOrder &&
                      activeDiscount.Criteria &&
                      activeDiscount.Criteria.length > 0 &&
                      activeDiscount.Stack &&
                      activeDiscount.Stacks > 1 &&
                      ` x ${activeDiscount.Stacks}`}
                  </strong>
                </td>
                <td className="regular-text-color right">
                  <strong>
                    - {CurrencyHelper.formatCurrency(event.CurrencySymbol, activeDiscount.Amount * 100.0)}
                  </strong>
                </td>
              </tr>
            )}
            {summary.allFees > 0 && (
              <tr>
                <td className="regular-text-color">
                  <strong>Fees</strong>
                </td>
                <td className="regular-text-color right">
                  <strong>{CurrencyHelper.formatCurrency(event.CurrencySymbol, summary.allFees)}</strong>
                </td>
              </tr>
            )}
            {summary.enhancedRefundServiceFee > 0 && (
              <tr>
                <td className="regular-text-color">
                  <strong>Enhanced Refund Service</strong>
                </td>
                <td className="regular-text-color right">
                  <strong>
                    {CurrencyHelper.formatCurrency(event.CurrencySymbol, summary.enhancedRefundServiceFee)}
                  </strong>
                </td>
              </tr>
            )}

            <tr>
              <td className="regular-text-color">
                <strong className="extra">Total</strong>
              </td>
              <td className="regular-text-color right">
                <strong className="extra">{totalPriceFormatted}</strong>
              </td>
            </tr>
          </tbody>
        </table>
      </Block>
    );
  };

  const renderErrorSection = () => {
    return (
      <div>
        <SpacerTable>
          <h1>Sorry, there has been a problem</h1>
          {orderError}
        </SpacerTable>

        <div className="spacer" />

        <SpacerTable>
          <Button
            onExecute={() => {
              setOrderError(null);
            }}
            className="confirm large"
            text="Dismiss"
          />
        </SpacerTable>
        <div className="spacer" />
      </div>
    );
  };

  const renderOrderSection = () => {
    if (!(stage == Stages.CardPayment || stage == Stages.OrderSetup) || !summary) {
      return null;
    }

    const eventHasTerms = event.TermsAndConditions != null && event.TermsAndConditions.length > 0;

    let anyUnansweredQuestions = false;

    tickets.forEach((ticket) => {
      const questions = event.Questions
        ? event.Questions.filter(
            (q) =>
              q.Required &&
              q.Criteria != null &&
              q.Criteria.length > 0 &&
              q.Criteria.filter((c) => c.Guid === ticket.TicketCategory.Guid).length > 0,
          )
        : [];

      if (questions.length > 0) {
        questions.forEach((question) => {
          const existingAnswers = ticket.QuestionAnswers.filter((a) => a.QuestionId === question.Id);

          if (!existingAnswers || existingAnswers.length == 0) {
            anyUnansweredQuestions = true;
          } else {
            if (question.QuestionType === QuestionTypes.Text) {
              existingAnswers.forEach((existingAnswer) => {
                if (existingAnswer.Text == null || existingAnswer.Text.length < 1) {
                  anyUnansweredQuestions = true;
                }
              });
            }
          }
        });
      }
    });

    let anyUnansweredOrderQuestions = false;

    const questions = event.Questions
      ? event.Questions.filter((q) => q.Required && (q.Criteria == null || q.Criteria.length === 0))
      : [];

    if (questions.length > 0) {
      questions.forEach((question) => {
        const existingAnswers = questionAnswers.filter((a) => a.QuestionId === question.Id);
        const existingAnswer = existingAnswers.length > 0 ? existingAnswers[0] : null;

        if (existingAnswer == null) {
          anyUnansweredOrderQuestions = true;
        } else {
          if (question.QuestionType === QuestionTypes.Text) {
            if (existingAnswer.Text == null || existingAnswer.Text.length < 1) {
              anyUnansweredOrderQuestions = true;
            }
          }
        }
      });
    }

    const orderQuestions = event.Questions
      ? event.Questions.filter((q) => q.Criteria == null || q.Criteria.length === 0)
      : [];

    const renderTicketsSection = () => {
      return (
        <>
          <table className="blocks tickets">
            <tbody>
              {!isAdmin && referral && (
                <Block className="center">
                  <strong>Arrived via {referral.name}’s link?</strong>
                  <br />
                  We’ll give them credit for your purchase, enjoy the event! 🤩
                </Block>
              )}
              {stage == Stages.CardPayment ? (
                <BlockInfo>
                  You have <span className="countdown" id="countdown"></span> to complete this order.
                </BlockInfo>
              ) : (
                <BlockInfo>
                  You have <span className="countdown" id="countdown"></span> to confirm your tickets.
                </BlockInfo>
              )}
              <OrderTicketSection
                isAdmin={props.isAdmin}
                confirmed={stage === Stages.CardPayment}
                onSeatedTicketCategoryChange={(_tickets) => {
                  refreshActiveDiscounts();
                }}
                currency={event.CurrencySymbol}
                tickets={tickets}
                event={event}
                seatCategories={props.seatCategories}
              />
              {renderShortSummarySection()}
            </tbody>
          </table>
        </>
      );
    };

    const renderPaymentMethodSection = () => {
      if (!paymentOptions || paymentOptions.length == 1) return null;

      return (
        <>
          <div className="spacer-x2" />

          <SpacerTable>
            <h1>Payment method</h1>
          </SpacerTable>

          <div className="spacer" />

          <table className="blocks">
            <tbody>
              {summary.total <= 0 ? (
                <>
                  <BlockInfo>No payment required as total is zero.</BlockInfo>
                </>
              ) : paymentOptions.length > 1 ? (
                <Block>
                  <Radio
                    selectedId={selectedPaymentMethod}
                    onValueChanged={(paymentMethod) => {
                      setSelectedPaymentMethod(paymentMethod.Id);
                    }}
                    options={paymentOptions}
                  />
                </Block>
              ) : (
                <Block>
                  <BlockHeader rightText={paymentOptions.length > 1 ? '(Required)' : ''}>
                    {paymentOptions[0].Text}
                  </BlockHeader>
                </Block>
              )}

              {selectedPaymentMethod == PaymentMethods.Request &&
                event.RequestingPasswordEnabled &&
                (props.isMember ? (
                  <BlockInfo type={InfoType.Info}>
                    As a member of {event.Organisation.Name} you will not require a password to request tickets.
                  </BlockInfo>
                ) : (
                  <Block>
                    <BlockHeader>Requesting Password</BlockHeader>
                    <BlockTextBox
                      type="password"
                      autoFocus
                      value={requestPassword}
                      onChange={(e) => {
                        setRequestPassword(e);
                      }}
                    />
                  </Block>
                ))}
              {selectedPaymentMethod == PaymentMethods.Request && (
                <BlockInfo type={InfoType.Info}>
                  Your request will only be approved on the basis that you are known to {event.Organisation.Name} and
                  they have your name and email on record. There is no guarentee or obligation of the organisation to
                  approve ticket requests.
                </BlockInfo>
              )}
            </tbody>
          </table>
        </>
      );
    };

    const renderQuestionsSection = () => {
      if (
        !(
          (orderQuestions && orderQuestions.length > 0) ||
          (event.CollectAtBoxOffice && event.AdmissionType != AdmissionTypes.Exchange)
        )
      )
        return null;

      return (
        <>
          <div className="spacer-x2" />
          <SpacerTable>
            <h1>Order questions</h1>
          </SpacerTable>
          <div className="spacer" />
          <table className="blocks">
            <tbody>
              {event.CollectAtBoxOffice && event.AdmissionType != AdmissionTypes.Exchange ? (
                stage != Stages.CardPayment ? (
                  <CheckBox
                    title="Collect at Box Office"
                    onBoxClick={() => setCollectAtBoxOffice(!collectAtBoxOffice)}
                    checked={collectAtBoxOffice}
                  >
                    {isAdmin ? (
                      <>Tick this option if the attendee wishes to collect their tickets at the box office.</>
                    ) : (
                      <>
                        Tick this option if you would like to collect your tickets at the event box office when you
                        arrive.
                        {event.AdmissionType == AdmissionTypes.PrintedAndMobile
                          ? ' If not, your tickets can be presented on your phone or printed.'
                          : ' If not, your tickets must be printed.'}
                      </>
                    )}
                  </CheckBox>
                ) : collectAtBoxOffice ? (
                  <BlockInfo>Tickets to be collected at box office.</BlockInfo>
                ) : (
                  <BlockInfo>No box office collection.</BlockInfo>
                )
              ) : null}
              {orderQuestions && orderQuestions.length > 0 && (
                <Block>
                  <OrderQuestions
                    event={event}
                    answers={questionAnswers}
                    onChange={(answers) => {
                      setQuestionAnswers(answers);
                    }}
                    readOnly={stage == Stages.CardPayment}
                  />
                </Block>
              )}
            </tbody>
          </table>
        </>
      );
    };

    const renderLegalSection = () => {
      return (
        <>
          <div className="spacer-x2" />
          <SpacerTable>
            <h1>The legal bit...</h1>
          </SpacerTable>

          <div className="spacer" />

          <table className="blocks">
            <tbody>
              {event.AdmissionType === AdmissionTypes.Exchange && (
                <BlockInfo>
                  All Seaty tickets for this event must be taken to the venue box office and exchanged prior to the
                  event starting.
                </BlockInfo>
              )}

              {showOrganisationTermsAndConditions && (
                <Block>
                  <BlockHeader>{event.Organisation.Name}</BlockHeader>
                  <BlockHeader>Terms and Conditions</BlockHeader>
                  {event.TermsAndConditions}
                </Block>
              )}

              <CheckBox
                title="Terms and Conditions"
                rightText={'(Required)'}
                onBoxClick={() => setAcceptedSeatyTerms(!acceptedSeatyTerms)}
                key="terms_checkbox"
                checked={acceptedSeatyTerms}
              >
                I have read and agree to the Seaty{' '}
                <a style={{ color: 'blue' }} href="https://seaty.co.uk/Docs/TermsOfService">
                  Terms of Service
                </a>
                {eventHasTerms ? ', ' : ' and '}
                <a style={{ color: 'blue' }} href="https://seaty.co.uk/Docs/Privacy">
                  Privacy Policy
                </a>
                {eventHasTerms ? (
                  <span>
                    {' '}
                    and the {event.Organisation.Name}{' '}
                    {showOrganisationTermsAndConditions ? (
                      <span>Terms & Conditions</span>
                    ) : (
                      <a
                        style={{ color: 'blue' }}
                        href="#!"
                        onClick={() => {
                          setShowOrganisationTermsAndConditions(true);
                        }}
                      >
                        Terms & Conditions
                      </a>
                    )}
                    .
                  </span>
                ) : null}
              </CheckBox>

              {event.AllowMarketing ? (
                <CheckBox
                  title="Marketing Permission"
                  onBoxClick={() => setAcceptedMarketing(!acceptedMarketing)}
                  key="contact_marketing_checkbox"
                  checked={acceptedMarketing}
                >
                  {isAdmin ? (
                    <>
                      This attendee is happy to receive information via email about similar events organised by{' '}
                      {event.Organisation.Name}. They understand they can unsubscribe by contacting{' '}
                      {event.Organisation.Name} at any time.
                    </>
                  ) : (
                    <>
                      I am happy to receive information via email about similar events organised by{' '}
                      {event.Organisation.Name}. I understand I can unsubscribe by contacting them at any time.
                    </>
                  )}
                </CheckBox>
              ) : null}

              {event.AllowSurvey ? (
                <CheckBox
                  title="Survey Permission"
                  onBoxClick={() => setAcceptedSurvey(!acceptedSurvey)}
                  key="contact_survey_checkbox"
                  checked={acceptedSurvey}
                >
                  {isAdmin ? (
                    <>
                      This attendee is happy for {event.Organisation.Name} to contact them by email with a one off
                      survey about this event.
                    </>
                  ) : (
                    <>
                      I am happy for {event.Organisation.Name} to contact me by email with a one off survey about this
                      event.
                    </>
                  )}
                </CheckBox>
              ) : null}
            </tbody>
          </table>
        </>
      );
    };

    return (
      <div>
        <SpacerTable>
          <h1>{event.Name}</h1>
          {moment.utc(eventDate.DateAsString).format('dddd Do MMMM YYYY') +
            ' at ' +
            moment.utc(eventDate.DateAsString).format('h:mma')}

          {event.Venue && event.Venue.Name && event.Venue.Name.length > 0 && (
            <div>
              {event.Venue.Name}
              {event.Venue.Postcode && event.Venue.Postcode.length > 0 ? ', ' + event.Venue.Postcode : ''}
            </div>
          )}
        </SpacerTable>

        <div className="spacer" />

        {stage == Stages.OrderSetup ? (
          <>
            {renderTicketsSection()}
            {renderDiscountCodeSection()}
            {renderNotesSection()}
            {renderPaymentMethodSection()}
            {renderQuestionsSection()}
            {renderAttendeeDetailsSection()}
            {renderLegalSection()}

            <div className="spacer-x2" />

            <SpacerTable>
              {selectedPaymentMethod != PaymentMethods.CardSeaty ? (
                <Button
                  disabled={anyUnansweredOrderQuestions || !attendeeDetailsValid()}
                  className="confirm large"
                  onExecute={submitOrder}
                  text={
                    isAdmin
                      ? 'Submit Order'
                      : selectedPaymentMethod == PaymentMethods.Request
                        ? 'Request Tickets'
                        : summary.total === 0
                          ? 'Book Tickets'
                          : 'Buy Tickets'
                  }
                />
              ) : (
                <Button
                  disabled={
                    !acceptedSeatyTerms ||
                    anyUnansweredQuestions ||
                    anyUnansweredOrderQuestions ||
                    !attendeeDetailsValid()
                  }
                  onExecute={() => {
                    if (isAdmin) {
                      continueToCardPayment();
                    } else {
                      setStage(Stages.EnhancedRefundService);
                      scrollToTop();
                    }
                  }}
                  className="confirm large"
                  text="Continue"
                />
              )}
            </SpacerTable>

            <div className="spacer" />
          </>
        ) : (
          <>
            {renderTicketsSection()}

            {gateway == PaymentGateway.Stripe && acceptedSeatyTerms && ticketAvailability && (
              <>
                <StripeCheckout
                  enhancedRefundService={enhancedRefundService}
                  referralGuid={!isAdmin && referral ? referral.guid : null}
                  event={event}
                  organisation={props.organisation}
                  eventDate={eventDate}
                  tickets={tickets}
                  eventTag={event.EventTag}
                  eventDateString={moment(eventDate.DateAsString).format('DDMMMYYYY')}
                  eventTimeString={moment(eventDate.DateAsString).format('HHmm')}
                  availability={ticketAvailability}
                  eventDateId={eventDate.Id}
                  attendeeEmail={attendeeEmail}
                  attendeeName={attendeeName}
                  notes={userNotes}
                  adminNotes={adminNotes}
                  questionAnswers={questionAnswers}
                  discount={activeDiscount}
                  discountCode={discountCode}
                  acceptedMarketing={acceptedMarketing}
                  acceptedSurvey={acceptedSurvey}
                  collectAtBoxOffice={collectAtBoxOffice}
                  stripeAccountId={orderConfig.event.Organisation.StripeAccountId}
                />
              </>
            )}

            <div className="spacer" />
          </>
        )}
      </div>
    );
  };

  const renderRefundProtectionSection = () => {
    if (stage != Stages.EnhancedRefundService) return null;

    return (
      <>
        <SpacerTable>
          <h1>Enhanced Refund Service</h1>
          <div className="spacer" />
          <strong>With this service,</strong> we can offer you a refund if you’re unable to attend an event due to
          <br />
          <ul>
            <li>🩹 An injury or illness (affecting you or a member of your immediate family) </li>
            <li>
              ⚠️ A public authority issuing a travel warning for the day of the event or the day before (including but
              not limited to adverse weather or terrorism)
            </li>
            <li>🚑 An emergency service requiring you to stay at home within 48 hours before the booked event</li>
          </ul>
          <strong>Without this service,</strong> you won’t be able to claim a refund if you or another ticket holder can
          no longer attend for these reasons.
          <div className="spacer" />
          You can find more info in our{' '}
          <a style={{ color: 'blue' }} href="https://seaty.co.uk/Docs/TermsOfService">
            Terms of Service
          </a>
          .
          <div className="spacer" />
          Would you like to add extra peace of mind with our Enhanced Refund Service for an additional{' '}
          <strong>
            {CurrencyHelper.formatCurrency(event.CurrencySymbol, summary.fees.potentialEnhancedRefundServiceFee)}
          </strong>
          ?
          <div className="spacer" />
          <table>
            <tbody>
              <tr>
                <td style={{ paddingRight: '5px' }}>
                  <Button
                    text={`Yes please`}
                    className={``}
                    onExecute={() => {
                      setEnhancedRefundService(true);
                      continueToCardPayment();
                    }}
                  />
                </td>
                <td style={{ paddingLeft: '5px' }}>
                  <Button
                    text={`No thanks`}
                    className=""
                    onExecute={() => {
                      setEnhancedRefundService(false);
                      continueToCardPayment();
                    }}
                  />
                </td>
              </tr>
            </tbody>
          </table>
        </SpacerTable>
        <div className="spacer" />
        <div className="spacer" />
        <table className="blocks tickets">
          <tbody>
            <BlockInfo>
              You have <span className="countdown" id="countdown"></span> to complete this order.
            </BlockInfo>
          </tbody>
        </table>
      </>
    );
  };

  if (orderError && orderError.length > 0) {
    return <>{renderErrorSection()}</>;
  }

  return (
    event &&
    eventDate &&
    tickets && (
      <>
        {busy && <Loader theme={ThemeHelper.getEventTheme(event)}>{busy}</Loader>}
        {renderOrderSection()}
        {renderCompletedSection()}
        {renderRefundProtectionSection()}
      </>
    )
  );
};

const Order: FunctionComponent<IProps> = (props) => {
  return <OrderComponent {...props}></OrderComponent>;
};

export default Order;
